/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('boxes', function (http, toast) {
    const url = config.apiURL + 'boxes/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (by, page = 1, pageSize) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.partners) {
                        _url += 'partners/';
                    }
                    if (by.types) {
                        _url += 'partners-types/';
                    }
                    if (by.type) {
                        params.push('filter[type]=' + by.type);
                    }
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results
                        for (let i in by) {
                            if (['id', 'type', 'partners'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }).catch(errorHandler);
            });
        },
        generate: (data) => {
            let _url = url + '/generate';
            return http.post(_url, data).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        add: (item, type = null) => {
            type = ['partners', 'partners-types'].indexOf(type) < 0 ? '' : type;
            return http.post(url + type, item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (item, type = null) => {
            type = ['partners', 'partners-types'].indexOf(type) < 0 ? '' : type + '/';
            return http.put(url + type + item._id, item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        delete: (item, type = null) => {
            type = ['partners', 'partners-types'].indexOf(type) < 0 ? '' : type;
            return http.delete(url + type + '/' + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        }
    };
});

